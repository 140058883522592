<template>
  <div class="page home_page">
    <div class="info">
      <van-icon name="arrow-left" class="back" @click="goBack" />
      <div>{{ scoreHistory && navData.score }}分</div>
      <div>
        {{ scoreHistory && navData.value }}
      </div>
    </div>
    <div
      class="van-row"
      style="min-height: calc(100vh - 50px); background: rgb(248, 245, 244); padding: 16px;"
    >
      <h1 class="colorgray085 f20 fw600">选择填报批次/段</h1>
      <div class="h5-default-table-block mt15">
        <div
          class="f16 colorgray9999 h5-default-table-block-item mb15"
          style="height: 64px;"
          v-for="(item, index) in piciList && piciList.list"
          :key="index"
          @click="clickPici(item)"
        >
          <div class="colorgray085  fw600" style="width: 28%;">
            {{ item.bzType }}
          </div>
          <div class="f14 text-right" style="width: 64%;">
            批次线：{{ item.score }}
          </div>
          <div style="width: 8%;">
            <i class="icon-zk van-icon van-icon-arrow van-cell__right-icon"></i>
          </div>
        </div>
        <!-- <div
          class="f16 colorgray9999 h5-default-table-block-item mb15"
          style="height: 64px;"
        >
          <div class="colorgray085  fw600" style="width: 28%;">专科</div>
          <div class="f14 text-right" style="width: 64%;">批次线：150</div>
          <div style="width: 8%;">
            <i class="icon-zk van-icon van-icon-arrow van-cell__right-icon"></i>
          </div>
        </div> -->
      </div>
      <div class="mt30 van-row">
        <div class="uzy-tzy-tip-instruction">
          <h6 class="f14 colorgray3333">重要提示：</h6>
          <div
            class="tzy f12 mt10 colorgray6666"
            v-html="piciList && piciList.content"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getParams, getNavData } from '@/utils';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      showTip: false, //
      showVip: false, //
      clearTimer: null,
      vipNum: 0,
      piciList: null,
      scoreHistory: null,
      navData: null,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
    }),
  },
  destroyed() {
    clearTimeout(this.clearTimer);
  },
  watch: {},
  async mounted() {
    console.log('===zz', this.userInfo);
    let newInfo = JSON.parse(localStorage.getItem('userInfo')) || null;
    if (newInfo) {
      await this.getPiciList();
      await this.getScoreHistory();
    }
  },
  created() {
    console.log('===zz', this.userInfo);
  },
  methods: {
    async getScoreHistory() {
      const apiUrl = '/php/user.score.get'; //'php/score.config.get';
      const res = await this.$axios.get(apiUrl);
      if (res.data.ret === 0) {
        this.scoreHistory = res.data.data;
        console.log('getScoreHistory', this.scoreHistory);
        this.navData = getNavData(this.scoreHistory.score);
        console.log('===navData', this.navData);
      }
    },
    //
    async getPiciList() {
      let apiUrl = `/php/pici.list`;
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        if (res.data.ret == 0) {
          this.piciList = res.data.data;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //clickPici
    clickPici(item) {
      // this.$toast('加速更新中，敬请期待~')
      //   if (true) return;
      this.$router.push({
        path: '/accounts/fill',
        query: {
          bzType: item.bzType,
          course: item.course,
          id: item.id,
          majorBzType: item.majorBzType,
          score: item.score,
        },
      });
    },
    //
    jumpVip() {
      this.$router.push({ path: '/accounts/vip' });
    },
    //开通VIP
    handleClickVip() {
      if (!this.vipNum) {
        this.showVip = true;
      }
    },
    close() {
      this.showVip = false;
    },
    clickVip() {
      this.showVip = false;
      this.clearTimer = setTimeout(() => {
        this.$router.push({ path: '/accounts/fill' });
      }, 500);
    },
    //推荐原理及说明
    handleTip() {
      this.showTip = true;
    },
    //返回
    goBack() {
      this.$router.go(-1);
    },
    //个性化设置
    clickSet() {
      this.$router.push({ path: '/accounts/one-fill' });
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  display: flex;
  position: absolute;
  top: 15px;
  left: 15px;
  align-items: center;
  div {
    color: #333;
    font-size: 16px;
    margin-right: 5px;
  }
  .back {
    color: #333;
    font-size: 18px;
    font-weight: 500;
    margin-right: 8px;
  }
}
.colorgray085 {
  color: rgba(0, 0, 0, 0.85);
}

.fw600 {
  font-weight: 600 !important;
}
.f20 {
  font-size: 20px !important;
}
.h5-default-table-block .h5-default-table-block-item {
  padding-left: 14px;
  background: #fff;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.colorgray9999 {
  color: #999;
}
.text-right {
  text-align: right;
}
.tzy {
  line-height: 24px;
}
.f12 {
  font-size: 12px !important;
}
.colorgray6666 {
  color: #666;
}
.page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(79,175,252);
  // background: linear-gradient(45deg,#419fe5,#5acbd3);
  background: #fff;
}
.fillBox {
  position: absolute;
  left: 50%;
  top: 20%;
  margin-left: -90px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  // border: solid 1px #fff;
  box-shadow: 0 0 30px #eee;
  // box-sizing: border-box;
  // background: linear-gradient(-45deg, #59c8d1, #46b1da);
  // text-align: center;
  // line-height: 180px;
  // color: #fff;
  // font-size: 24px;
  // letter-spacing: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100% !important;
    padding: 0 !important;
  }
}
.tip_box {
  border-radius: 8px 8px 0 0;
  padding: 16px 28px;
  // padding-bottom: 60px;
  .title {
    margin: 16px 0 8px 0;
    margin-bottom: 10px;
    width: 112px;
    height: 20px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #212121;
    line-height: 20px;
  }
  .text {
    display: inline-block;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #212121;
    line-height: 20px;
  }
}
.vip_box {
  padding: 20px 14px 50px;
  .vip_text {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .vip_btn {
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    /deep/ .btn {
      border-radius: 100px;
      height: 40px !important;
      line-height: 1.2;
      width: 40%;
      margin: 0 2%;
    }
    /deep/ .btn1 {
      background: #fff;
      color: #212121;
      border: 1px solid #9b9b9b;
    }
    /deep/ .btn2 {
      background: -webkit-linear-gradient(left, #ff6034, #ee0a24);
      background: linear-gradient(90deg, #ff6034, #ee0a24);
      border: none !important;
      color: #fff;
    }
  }
}
</style>
